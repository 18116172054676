import { PageElement, html, css } from 'Components';
import { Session } from 'Utils';

import './user';
import './admin';
import './soc';
import './doc';
import './dev';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .grid {
          display:grid;
          grid-template-columns: repeat(5, 1fr); /* Crée 3 colonnes de taille égale */
          margin:10px;
          gap:20px;
        }

        box-styled[variant="warning"] {
          font-size:0.9em;
        }

        @media (max-width: 670px) {
          .grid {
            display:grid;
            grid-template-columns: repeat(2, 1fr); /* Crée 3 colonnes de taille égale */
            margin:10px;
          }
        }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english: {
          translation: {
            title: 'My Space',
            account: 'My Account',
            admin: 'Admin space',
            soc: 'SOC space',
            dev: 'Developer space',
            doc: 'Documentations'
          },
        },
        french: {
          translation: {
            title: 'Mon espace',
            account: 'Mon compte',
            admin: 'Espace Administration',
            soc: 'Espace SOC',
            dev: 'Espace développeur',
            doc: 'Documentations'
          }
        }
      }
    ];
  }

  constructor() {
    super();
    this.layout = '';
  }
  
  render() {
    return html`
      <section-header img="assets/img/monespace.png">${this._tl('title')}</section-header>
      <br/>
      <div class="grid">
        <box-styled layout="${this.layout}" micon="account_circle" aroute="/private/user/account">${this._tl('account')}</box-styled>
        ${Session.isBoss() ? html`<box-styled layout="${this.layout}" micon="settings" aroute="/private/admin">${this._tl('admin')}</box-styled>` : '' }
        ${Session.isSoc() ? html`<box-styled layout="${this.layout}" micon="shield" aroute="/private/soc">${this._tl('soc')}</box-styled>` : '' }
        ${Session.isBoss() ? html`<box-styled layout="${this.layout}" micon="developer_board" aroute="/private/dev">${this._tl('dev')}</box-styled>` : '' }
        <box-styled layout="${this.layout}" micon="quiz" aroute="/private/doc">${this._tl('doc')}</box-styled>
      </div>
      <br/><br/>
    `;
  }

}

customElements.define('page-user-home', Page);